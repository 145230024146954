
import { Component, Vue, Mixins, Prop, Watch, Emit } from "vue-property-decorator";
import RegionData from "@/mixins/regionData";

import dayjs from "dayjs";

import {
  editUserInfo,
  getUserInfo,
} from "@/api/userMember";

import { getCountryCodeList } from "@/api/common";

@Component
export default class BasicInfo extends Mixins(RegionData) {
  @Prop({ default: {} }) readonly parentData: any;
  @Prop({ default: "" }) readonly userId?: string;
  formData =
    {
      id: "",
      gender: 1,
      realName: "",
      phone: "",
      birthday: "",
      height: "",
      weight: "",
      address: [""],
      career: "",
      age: "",
      areaCode: "",
    };
  userVipInfo = {};
  pickerOptions = {
    disabledDate(date: Date) {
      if (dayjs(date).isAfter(dayjs())) return true;
      return false
    }
  }
  rules = {
    areaCode: [{ required: true, message: "请选择国家地区", validator: this.validateAreaCode, trigger: 'blur' }]
  };
  loading = false;
  btnLoading = false

  countryCodeList = [];


  validateAreaCode(rule, value, callback) {
    if (this.formData.areaCode && this.formData.phone) {
      callback();
      return;
    }
    return callback(new Error())
  }

  mounted() {
    // @ts-ignore
    this.$refs.validateForm?.resetFields()
    this.loading = true;
    getUserInfo({ id: this.userId }).then((res) => {
      const { gender = 1, realName, phone, birthday, height, weight, address = "", career, areaCode } = res.data || {};
      const age = this.calcAge(birthday);
      Object.assign(this.formData, { areaCode: areaCode || "", age, gender, realName, phone, birthday, height, weight, address: address ? address.split(",") : [], career})
      this.getCountryCodeList()
    }).finally(() => {
      this.loading = false;
    })
  }

  getCountryCodeList() {
    getCountryCodeList().then((res) => {
      const { list = [], _default } = res.data;
      this.countryCodeList = list;
      // this.formData.areaCode = _default.areaCode;
    })
  }

  calcAge(birthday) {
    if (!birthday) return "";
    const bir = dayjs(birthday);
    const now = dayjs();
    const diff = now.diff(bir, "year");
    if (diff < 0) return "0岁"
    return `${diff}岁`
  }

  addressChange($event: any) {
    console.log($event)
    console.log(this.formData)
  }

  phoneInput(newValue: string) {
    this.formData.phone = newValue.replace(/[^(\d)]/g,'');
  }
  
  heiWeiInput(newValue: string, key: string) {
    if (newValue == "") return "";
    // @ts-ignore
    this.formData[key] = newValue.replace(/[^(\d)]/g,'');
  }

  // NOTE: 先保留着
  // dialogConfirm() {
  //   let params = {
  //     id: this.formData.id,
  //     nickname: this.formData.nickname,
  //     phone: this.formData.phone,
  //     gender: this.formData.gender,
  //   };
  //   // @ts-ignore
  //   this.$refs["validateForm"]?.validate((valid: any) => {
  //     if (valid) {
  //       updateUser(params)
  //         .then((res) => {
  //           this.handleClose();
  //           this.getData();
  //         })
  //         .catch((err) => { });
  //     } else {
  //       console.log("error submit!!");
  //       return false;
  //     }
  //   });


  dialogConfirm() {
    const { gender, realName, phone, birthday, height, weight, address, career, areaCode } = this.formData;
    if(parseInt(this.formData.height, 10) < 50 || parseInt(this.formData.height, 10) > 300) {
      this.$message.error('身高请输入50到300之间的数字');
      return;
    }
    if(parseInt(this.formData.weight, 10) < 10 || parseInt(this.formData.weight, 10) > 400) {
      this.$message.error('体重请输入10到400之间的数字');
      return;
    }
    let params = {
      id: this.userId,
      gender, realName, phone, 
      birthday: birthday ? dayjs(birthday).format("YYYY-MM-DD HH:mm:ss") : "", 
      height, weight, address: address.join(","), career, areaCode
    };
    this.btnLoading = true;
    // @ts-ignore
    this.$refs["validateForm"]?.validate().then(() => {
      editUserInfo(params)
        .then((res) => {
          this.handleClose();
          this.getData();
        }).finally(() => {
          this.btnLoading = false;
        })
    }).catch((err) => {
      this.btnLoading = false;
    });
  }

  @Watch("parentData", { immediate: true, deep: true })
  setFormData(value: any) {
  }

  @Emit("handleClose")
  handleClose() {
    return false;
  }

  @Emit("getData")
  getData() {
    return true;
  }

}
